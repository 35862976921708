import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  mainWhite,
  screen,
  mainBlue,
  mainBlack,
  larsseit,
} from "../components/common/variables";
import { StaticImage } from "gatsby-plugin-image";
import { ScrollLocky } from "react-scroll-locky";
import styled from "styled-components";
import LabeledArrow from "../components/labeled-arrow";

const Wrapper = styled.div`
  background: ${mainBlack};
  color: ${mainWhite};
  padding: 0 26px;
  height: calc(100vh - 110px);
  position: relative;
  @media ${screen.xsmall} {
    padding: 0 60px;
    height: calc(100vh - 310px);
  }
  @media ${screen.small} {
    padding: 0 80px;
  }
  @media ${screen.medium} {
    padding: 0 7vw;
  }

  .flex {
    max-width: 900px;
    margin: 0 0 0 auto;

    position: relative;
    z-index: 10;
    @media ${screen.xsmall} {
      text-align: right;
    }

    .heading {
      font-family: ${larsseit};
      line-height: 1.23;
      font-size: 2.53rem;
      font-weight: 400;
      max-width: 320px;
      @media ${screen.xsmall} {
        font-size: 3.3rem;
        max-width: none;
      }
      @media ${screen.small} {
        font-size: 4rem;
      }
      @media ${screen.medium} {
        font-size: clamp(1.4rem, calc(1.4rem + 3vw), 5.01rem);
      }
    }

    .subheading {
      font-family: ${larsseit};
      line-height: 1.23;
      font-size: 1.28rem;
      font-weight: 400;
      margin: 5px 0 0 0;
      max-width: 250px;
      @media ${screen.xsmall} {
        font-size: 1.7rem;
        margin: 6px 0 0 0;
        max-width: none;
      }
      @media ${screen.small} {
        font-size: 2rem;
      }
      @media ${screen.medium} {
        font-size: 2.1rem;
      }
      @media ${screen.large} {
        font-size: 2.5rem;
      }
    }

    .navigate {
      margin: 20px 0 0 0;

      @media ${screen.xsmall} {
        display: flex;
        justify-content: flex-end;
        margin: 49px 0 0 0;
      }

      .label {
        color: ${mainBlue};
      }
      .arrow-line {
        stroke: ${mainBlue} !important;
      }

      @media ${screen.withCursor} {
        &:hover .label {
          stroke: ${mainBlue} !important;
        }
        &:hover .arrow-line {
          stroke: ${mainBlue} !important;
        }
      }
    }
  }

  .absolute-bg {
    width: calc(600px + 32vw);
    position: absolute;
    left: -45vw;
    bottom: 0;
    @media ${screen.xsmall} {
      bottom: -122px;
      left: -70px;
      max-width: calc(750px + 21vw);
      width: 100%;
    }
  }
`;

const BuiltByPage = () => {
  return (
    <Layout colourNavbar={mainBlack} isContactPage isBuiltByPage>
      <SEO title="Built By Handsome" />
      <ScrollLocky isolation={false}>
        <Wrapper>
          <div className="inner-wrapper">
            <div className="flex">
              <h3 className="heading">We build websites well.</h3>
              <p className="subheading">
                Can't say the same about our jenga towers.
              </p>

              <div className="navigate">
                <LabeledArrow label="GET ME OUTTA HERE" url="/" />
              </div>
            </div>
          </div>

          <div
            className="absolute-bg"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <StaticImage
              src="../images/handsome-creative-we-build-brands.png"
              alt="Handsome team"
              placeholder="blurred"
            />
          </div>
        </Wrapper>
      </ScrollLocky>
    </Layout>
  );
};

export default BuiltByPage;
